import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { IEntitySearchParams, ExtraSearchParams, SearchParams } from '@mt-ng2/common-classes';
import { BaseService } from '@mt-ng2/base-service';

import { IProduct } from '@model/interfaces/product';
import { IProductAvailabilityDTO } from '@model/interfaces/custom/product-availability-dto';
import { IUnitsSoldByYearDTO } from '@model/interfaces/custom/units-sold-by-year-dto';
import { IInventoryBucketWithAvailabilityDTO } from '@model/interfaces/custom/inventory-bucket-with-availability-dto';
import { IProductPricingDto } from '@model/interfaces/custom/product-pricing-dto';

export const emptyProduct: IProduct = {
    Id: 0,
    Name: null,
    LabelName: null,
    CannotAcceptLabel: false,
    Code: null,
    ListPriceDateModified: new Date(),
    PlantId: null,
    ProductCategoryId: null,
    ContainerSizeId: null,
    ContainerColorId: null,
    OversoldTreatmentId: null,
    Barcode: null,
    Notes: null,
    ListPrice: null,
    PerBow: null,
    PerRow: null,
    Spaced: null,
    Discontinued: null,
    MarketingMaterials: false,
    Salable: true,
    OnWebsite: true,
    Archived: false,
    RackGroupId: null,
    RackGroup: null,
};

enum ProductRecipeStatuses {
    HasProductRecipes = 1,
    HasNoProductRecipes = 2,
}

export enum FilterOption {
    Yes = 1,
    No = 2,
    Both = 3,
}

export class ProductFilterStates {
    query = '';
    selectedProductCategories: number[] = [];
    selectedContainerSizes: number[] = [];
    selectedContainerColors: number[] = [];
    productRecipeOptions: MtSearchFilterItem[] = [
        { Item: { Name: 'Has No Product Recipes', Id: ProductRecipeStatuses.HasNoProductRecipes } } as MtSearchFilterItem,
        { Item: { Name: 'Has Product Recipes', Id: ProductRecipeStatuses.HasProductRecipes } } as MtSearchFilterItem,
    ];
    salableOptions: MtSearchFilterItem[] = [
        { Item: { Name: 'Salable', Id: FilterOption.Yes } } as MtSearchFilterItem,
        { Item: { Name: 'Not Salable', Id: FilterOption.No } } as MtSearchFilterItem,
        { Item: { Name: 'Both', Id: FilterOption.Both }, Selected: true } as MtSearchFilterItem,
    ];
    onWebsiteOptions: MtSearchFilterItem[] = [
        { Item: { Name: 'On Website', Id: FilterOption.Yes } } as MtSearchFilterItem,
        { Item: { Name: 'Not On Website', Id: FilterOption.No } } as MtSearchFilterItem,
        { Item: { Name: 'Both', Id: FilterOption.Both }, Selected: true } as MtSearchFilterItem,
    ];
    discontinuedOptions: MtSearchFilterItem[] = [
        { Item: { Name: 'Discontinued', Id: FilterOption.Yes } } as MtSearchFilterItem,
        { Item: { Name: 'Not Discontinued', Id: FilterOption.No }, Selected: true } as MtSearchFilterItem,
        { Item: { Name: 'Both', Id: FilterOption.Both } } as MtSearchFilterItem,
    ];
    acceptLabelOptions: MtSearchFilterItem[] = [
        { Item: { Name: 'Accept Labels', Id: FilterOption.Yes } } as MtSearchFilterItem,
        { Item: { Name: 'Cannot Accept Labels', Id: FilterOption.No } } as MtSearchFilterItem,
        { Item: { Name: 'Both', Id: FilterOption.Both }, Selected: true } as MtSearchFilterItem,
    ];
}

@Injectable({
    providedIn: 'root',
})
export class ProductService extends BaseService<IProduct> {
    productFilterStates = new ProductFilterStates();
    constructor(public http: HttpClient) {
        super('/products', http, null, { entityName: 'Product' });
    }

    formatTitleText(entity: IProduct): void {
        this.setTitle(`Product: ${entity.Name}`);
    }

    getEmptyProduct(): IProduct {
        return { ...emptyProduct };
    }

    getAll(): Observable<IProduct[]> {
        return super.getAll().pipe(tap((products) => this.formatProductForDropdown(products)));
    }

    getAvailableProducts(): Observable<IProduct[]> {
        const searchparams = this.buildProductSearchParams();
        return super.get(searchparams).pipe(map((resp) => this.formatProductForDropdown(resp.body)));
    }

    getProductsForMasterOrder(): Observable<IProduct[]> {
        const searchparams = this.buildProductSearchParams(true);
        return super.get(searchparams).pipe(map((resp) => resp.body));
    }

    getProductsForProductDropdown(term: string, productId: number = null): Observable<IProduct[]> {
        const searchparams = this.buildProductSearchParams();
        searchparams.query = term;
        if (productId) {
            searchparams.extraParams.push(new ExtraSearchParams({ name: 'ProductId', value: productId.toString() }));
        }
        return super.get(searchparams).pipe(map((resp) => this.formatProductForDropdown(resp.body)));
    }

    generateBarcode(): Observable<string> {
        return this.http.get<string>('/products/generate-barcode');
    }

    getQtyAvailableByProductAndBucket(productId: number, bucketId: number): Observable<number> {
        return this.http.get<number>(`/products/${productId}/inventory-bucket/${bucketId}`);
    }

    getProductAvailabilityDTO(searchParams: SearchParams): Observable<HttpResponse<IProductAvailabilityDTO[]>> {
        const params = this.getHttpParams(searchParams);
        return this.http.get<IProductAvailabilityDTO[]>(`/products/availability`, {
            observe: 'response',
            params: params,
        });
    }

    resetOversoldTreatment(ids: number[]): Observable<boolean> {
        return this.http.put<boolean>('/products/availability/reset-treatment', ids);
    }

    exportProductAvailabitySheet(searchParams: SearchParams): Observable<unknown> {
        const params = this.getHttpParams(searchParams);
        return this.http.get(`/products/availability/export/excel`, {
            params: params,
            responseType: 'blob' as const,
        });
    }
    exportProductAvailabityPdf(searchParams: SearchParams): Observable<unknown> {
        const params = this.getHttpParams(searchParams);
        return this.http.get(`/products/availability/export/pdf`, {
            params: params,
            responseType: 'blob' as const,
        });
    }

    getProductsSoldByYearSummary(productId: number): Observable<IUnitsSoldByYearDTO[]> {
        return this.http.get<IUnitsSoldByYearDTO[]>(`/products/${productId}/units-sold-by-year`);
    }

    getBestInventoryBucketForMasterOrderByProduct(productId: number): Observable<IInventoryBucketWithAvailabilityDTO> {
        return this.http.get<IInventoryBucketWithAvailabilityDTO>(`/products/${productId}/mo-bucket`);
    }

    getAllMasterOrderPotentialBucketsByProductId(productId: number): Observable<IInventoryBucketWithAvailabilityDTO[]> {
        return this.http.get<IInventoryBucketWithAvailabilityDTO[]>(`/products/${productId}/mo-buckets`);
    }

    getCustomerPricing(productId: number, customerId: number, shipToId?: number): Observable<IProductPricingDto[]> {
        const route = shipToId
            ? `/products/${productId}/pricing/customer/${customerId}/ship-to/${shipToId}`
            : `/products/${productId}/pricing/customer/${customerId}`;
        return this.http.get<IProductPricingDto[]>(route);
    }

    getProductPricing(productId: number): Observable<IProductPricingDto[]> {
        return this.http.get<IProductPricingDto[]>(`/products/${productId}/pricing`);
    }

    private buildProductSearchParams(forMo = false): SearchParams {
        const extraParams = [
            new ExtraSearchParams({
                name: 'ShowSalable',
                value: '1',
            }),
            new ExtraSearchParams({
                name: 'ShowDiscontinued',
                value: '0',
            }),
            new ExtraSearchParams({
                name: 'CategoryNotArchived',
                value: true.toString(),
            }),
        ];

        if (forMo) {
            extraParams.push(
                new ExtraSearchParams({
                    name: 'InMoCategory',
                    value: true.toString(),
                }),
            );
        }

        const searchEntity: IEntitySearchParams = {
            extraParams: extraParams,
            order: 'Code',
            orderDirection: 'asc',
            query: '',
        };

        return new SearchParams(searchEntity);
    }

    private formatProductForDropdown(products: IProduct[]): IProduct[] {
        return products.map((p) => {
            p.Name = `${p.Name} - ${p.ProductCategory?.Name} - ${p.ContainerSize?.Name}`;
            return p;
        });
    }
}
