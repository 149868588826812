import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { KeyboardShortcutService } from '@mt-ng2/keyboard-shortcuts-module';
import { BackButtonModule } from '@mt-ng2/back-button-module';
import { CommonService } from './services/common.service';
import { KeyboardShortcutModule } from '@mt-ng2/keyboard-shortcuts-module';
import { auditLogModuleConfig } from './configs/audit-log.config';
import { AuditLogUserService } from './services/audit-log-user.service';
import { MtManagedListControlModule } from '@mt-ng2/managed-list-control';
import { AdminPortalFeatureModule } from './feature.module';
import { TemperaturePipe } from './pipes/temperature.pipe';
import { MtSingleFilterSelectComponent } from './components/other/mt-single-filter-select/mt-single-filter-select';
import { TextInlineEditDynamicCell } from './components/inline-edit/text-inline-edit.dynamic-cell';
import { EntityDropdownInlineEditDynamicCell } from './components/inline-edit/entity-dropdown-inline-edit.dynamic-cell';
import { SimpleDropdownInlineEditDynamicCell } from './components/inline-edit/simple-dropdown-inline-edit.dynamic-cell';
import { CheckboxInlineEditDynamicCell } from './components/inline-edit/checkbox-inline-edit.dynamic-cell';
import { NumberInlineEditDynamicCell } from './components/inline-edit/number-inline-edit.dynamic-cell';
import { RGBInlineEditDynamicCell } from './components/inline-edit/rgb-inline-edit.dynamic-cell';
import { SimpleDropdownComponent } from './components/other/simple-dropdown.component';
import { EntityDropdownComponent } from './components/other/entity-dropdown.component';
import { TextPopupDynamicCell } from './components/inline-edit/text-popup/text-popup.dynamic-cell';
import { NonSavingTextPopupDynamicCell } from './components/inline-edit/text-popup/non-saving-text-popup.dynamic-cell';
import { DateInlineEditDynamicCell } from './components/inline-edit/date-inline-edit.dynamic-cell';
import { ModalModule } from '@mt-ng2/modal-module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgMultiselectComponent } from './components/other/ng-multiselect/ng-multiselect.component';
import { EntityDropdownInlineCreateDynamicCell } from './components/inline-create/entity-dropdown-inline-create.dynamic-cell';
import { SimpleDropdownInlineCreateDynamicCell } from './components/inline-create/simple-dropdown-inline-create.dynamic-cell';
import { NumberInlineCreateDynamicCell } from './components/inline-create/number-inline-create.dynamic-cell';
import { TextInlineCreateDynamicCell } from './components/inline-create/text-inline-create.dynamic-cell';
import { CheckboxInlineCreateDynamicCell } from './components/inline-create/checkbox-inline-create.dynamic-cell';
import { TextPopupInlineCreateDynamicCell } from './components/inline-create/text-popup-inline-create.dynamic-cell';
import { DateInlineCreateDynamicCell } from './components/inline-create/date-inline-create.dynamic-cell';
import { SaveCancelButtonsComponent } from './components/other/save-cancel-buttons.component';
import { DeleteDynamicCell } from './components/other/delete.dynamic-cell';
import { HistoryPopupDynamicCell } from './components/other/history-popup/history-popup.dynamic-cell';
import { DatePicker } from './components/other/date-picker.component';
import { SimpleCheckboxComponent } from './components/other/simple-checkbox.component';
import { RGBComponent } from './components/other/rgb.component';
import { RGBInlineCreateDynamicCell } from './components/inline-create/rgb-inline-create.dynamic-cell';
import { DynamicLabelComponent } from './components/other/dynamic-label.component';
import { NotesComponent } from './components/notes/notes.component';
import { VisibilityModule } from '@mt-ng2/visibility-module';
import { NoteDisplayComponent } from './components/notes/note-display.component';
import { MtPhoneControlModule } from '@mt-ng2/phone-control';
import { EntityComponentsPhonesModule } from '@mt-ng2/entity-components-phones';
import { NavigationService } from './services/navigation.service';
import { MtDateModule } from '@mt-ng2/date-module';
import { TypeAheadModule } from '@mt-ng2/type-ahead-control';
import { SimpleLabeledFieldComponent } from './components/other/simple-labeled-field';
import { DragAndDropEntityListComponent } from './components/drag-drop-entity-list/drag-and-drop-entity-list.component';
import { EntityListDynamicCellDirective } from './components/drag-drop-entity-list/entity-list-dynamic-cell-directive/entity-list-dynamic-cell.directive';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OpenNoteComponent } from './components/open-notes/open-note.component';
import { AppIconButtonComponent } from './components/other/icon-button.component';
import { CityAndStatePipe } from './pipes/city-and-state.pipe';
import { EmailNoteComponent } from './components/email/email-note.component';
import { ReportUsersService } from './services/report-users.service';
import { reportingModuleConfig } from './configs/reporting.config';
import { FullNamePipe } from './pipes/user-fullname.pipe';
import { SavedSearchComponent } from './components/saved-search/saved-search.component';
import { AuditLoggingModuleConfigToken, AuditLoggingModuleUserServiceToken } from '@mt-ng2/audit-logging-module';
import { DynamicFormModuleConfigToken } from '@mt-ng2/dynamic-form';
import { EntityListModuleConfigToken, IEntityListModuleConfig } from '@mt-ng2/entity-list-module';
import { AdvancedReportingModuleConfigToken, AdvancedReportingModuleUserServiceToken } from '@mt-ng2/advanced-reporting-module';
import { NavModule } from '@mt-ng2/nav-module';
import { AppSearchFilterDaterangeComponent } from './components/daterange-filter/daterange-filter.compontent';
import { BreckCommonModule } from '@mt-ng2/common';
import { ProductDropdownComponent } from './components/other/product-dropdown.component';
import { CustomTimeFieldsComponent } from './components/custom-time-fields/custom-time-fields.component';
import { TimepickerControlModule } from '@mt-ng2/timepicker-control';

export const entityListModuleConfig: IEntityListModuleConfig = {
    itemsPerPage: 15,
    defaultNullDisplayValue: '',
};

/**
 * Shared Module contains all imports and exports that are commonly used in all other modules.
 * Since this is imported into the AppModule, only things that are needed on startup should be
 * included.
 *
 * Is imported into nearly all other modules, including the root module "AppModule".  forRoot
 * is only called from AppModule.
 *
 * Tip: If the import/export should only be included in feature modules, than consider putting
 * it into "AdminPortalFeatureModule" instead.  If the import/export is only needed at the AppModule level
 * and rarely/never needs to be imported into any other modules, than consider putting it into
 * "AppModule" instead.
 */
@NgModule({
    declarations: [
        DatePicker,
        DynamicLabelComponent,
        EntityDropdownComponent,
        MtSingleFilterSelectComponent,
        NgMultiselectComponent,
        NoteDisplayComponent,
        NotesComponent,
        RGBComponent,
        SaveCancelButtonsComponent,
        SimpleCheckboxComponent,
        SimpleDropdownComponent,
        SimpleLabeledFieldComponent,
        DragAndDropEntityListComponent,
        EntityListDynamicCellDirective,
        OpenNoteComponent,
        AppIconButtonComponent,
        EmailNoteComponent,
        SavedSearchComponent,
        AppSearchFilterDaterangeComponent,
        ProductDropdownComponent,
        CustomTimeFieldsComponent,

        // Pipes
        TemperaturePipe,
        CityAndStatePipe,
        FullNamePipe,

        // Inline Edits
        CheckboxInlineEditDynamicCell,
        DateInlineEditDynamicCell,
        EntityDropdownInlineEditDynamicCell,
        NumberInlineEditDynamicCell,
        RGBInlineEditDynamicCell,
        SimpleDropdownInlineEditDynamicCell,
        TextInlineEditDynamicCell,

        // Inline Creates
        CheckboxInlineCreateDynamicCell,
        DateInlineCreateDynamicCell,
        EntityDropdownInlineCreateDynamicCell,
        NumberInlineCreateDynamicCell,
        RGBInlineCreateDynamicCell,
        SimpleDropdownInlineCreateDynamicCell,
        TextInlineCreateDynamicCell,
        TextPopupInlineCreateDynamicCell,

        // Other dynamic cells
        DeleteDynamicCell,
        HistoryPopupDynamicCell,
        NonSavingTextPopupDynamicCell,
        TextPopupDynamicCell,
    ],
    exports: [
        CommonModule,
        NgbModule,
        RouterModule,
        DynamicLabelComponent,
        EntityDropdownComponent,
        FormsModule,
        BackButtonModule,
        KeyboardShortcutModule,
        ModalModule,
        MtManagedListControlModule,
        MtSingleFilterSelectComponent,
        NgMultiselectComponent,
        NgxMaskModule,
        NoteDisplayComponent,
        MtPhoneControlModule,
        MtDateModule,
        NotesComponent,
        ReactiveFormsModule,
        RGBComponent,
        SimpleCheckboxComponent,
        SimpleDropdownComponent,
        SimpleLabeledFieldComponent,
        DatePicker,
        DragAndDropEntityListComponent,
        OpenNoteComponent,
        AppIconButtonComponent,
        EmailNoteComponent,
        SavedSearchComponent,
        AppSearchFilterDaterangeComponent,
        ProductDropdownComponent,

        // Pipes
        TemperaturePipe,
        CityAndStatePipe,
        FullNamePipe,
        // Inline Edits
        TextInlineEditDynamicCell,
        NumberInlineEditDynamicCell,
        SimpleDropdownInlineEditDynamicCell,
        EntityDropdownInlineEditDynamicCell,
        CheckboxInlineEditDynamicCell,
        DateInlineEditDynamicCell,
        RGBInlineEditDynamicCell,
        // Inline Creates
        TextInlineCreateDynamicCell,
        NumberInlineCreateDynamicCell,
        SimpleDropdownInlineCreateDynamicCell,
        EntityDropdownInlineCreateDynamicCell,
        CheckboxInlineCreateDynamicCell,
        DateInlineCreateDynamicCell,
        TextPopupInlineCreateDynamicCell,
        RGBInlineCreateDynamicCell,
        // Other dynamic cells
        DeleteDynamicCell,
        HistoryPopupDynamicCell,
        TextPopupDynamicCell,
        NonSavingTextPopupDynamicCell,
        SaveCancelButtonsComponent,
        NavModule,
        BreckCommonModule,
    ],
    imports: [
        CommonModule,
        NgbModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule,
        ModalModule,
        BackButtonModule,
        KeyboardShortcutModule,
        MtManagedListControlModule,
        AdminPortalFeatureModule,
        NgSelectModule,
        NavModule,
        MtPhoneControlModule,
        TypeAheadModule,
        VisibilityModule,
        MtDateModule,
        DragDropModule,
        EntityComponentsPhonesModule,
        BreckCommonModule,
        TimepickerControlModule,
    ],
})
export class AdminPortalSharedModule {
    static forRoot(): any {
        return {
            ngModule: AdminPortalSharedModule,
            providers: [
                ReportUsersService,
                KeyboardShortcutService,
                NavigationService,
                { provide: EntityListModuleConfigToken, useValue: entityListModuleConfig },
                { provide: DynamicFormModuleConfigToken, useValue: { commonService: CommonService } },
                { provide: AuditLoggingModuleConfigToken, useValue: auditLogModuleConfig },
                { provide: AuditLoggingModuleUserServiceToken, useExisting: AuditLogUserService },
                { provide: AdvancedReportingModuleConfigToken, useValue: reportingModuleConfig },
                { provide: AdvancedReportingModuleUserServiceToken, useExisting: ReportUsersService },
            ],
        };
    }
}
