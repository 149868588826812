import { Component, ChangeDetectorRef, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { CustomFormControlComponentBase } from '@mt-ng2/dynamic-form';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

enum AMPM {
    AM = 'AM',
    PM = 'PM',
}

@Component({
    styles: [
        `
            .timepicker {
                width: 255px;
                height: 32px;
                margin-bottom: 15px;
            }
            .control-label {
                margin-bottom: 5px;
            }
        `,
    ],
    templateUrl: './custom-time-fields.component.html',
})
export class CustomTimeFieldsComponent extends CustomFormControlComponentBase implements OnInit, AfterViewInit {
    constructor(changeDetectorRef: ChangeDetectorRef) {
        super(changeDetectorRef);
    }

    private _timeModel: NgbTimeStruct = {
        hour: null,
        minute: 0,
        second: 0,
    };

    @ViewChild('timePicker') timePicker: any;

    previousAMPM = null;

    get timeModel(): NgbTimeStruct {
        return this._timeModel;
    }
    set timeModel(value: NgbTimeStruct) {
        if (value) {
            this._timeModel = value;
            this.config.value = `${this._timeModel.hour}:${this._timeModel.minute}:00`;
        } else {
            this._timeModel.hour = this.timePicker?.model?.hour;
            this._timeModel.minute = 0;
        }
        const ampm = this._timeModel.hour >= 12 ? AMPM.PM : AMPM.AM;
        // ampm button has no blur event, so we trigger on change instead
        if (this.previousAMPM && this.previousAMPM !== ampm) {
            this.previousAMPM = ampm;
            this.setOnBlur();
        }
    }

    showTimepicker = false;

    ngOnInit(): void {
        super.ngOnInit();
        const timeParts = (this.config.value as string)?.split(':').map((x: string) => parseInt(x, 10));
        if (timeParts?.length) {
            this.timeModel = {
                hour: timeParts[0],
                minute: timeParts[1],
                second: 0,
            };
            this.previousAMPM = this.timeModel.hour >= 12 ? AMPM.PM : AMPM.AM;
        } else {
            this.timeModel = null;
        }
        this.showTimepicker = true;
    }

    clear(): void {
        this.timeModel = null;
        this._timeModel.hour = null;
        this._timeModel.minute = 0;
        this.showTimepicker = false;
        setTimeout(() => {
            this.showTimepicker = true;
        }, 0);
        this.config.value = null;
        this.setFieldValue();
    }

    setOnBlur(): void {
        if (this.timeModel.hour && !this.timeModel.minute) {
            this.timeModel.minute = 0;
        }
        if (this.timeModel.hour) {
            this.config.value = `${this.timeModel.hour}:${this.timeModel.minute}:00`;
            this.setFieldValue();
        }
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    onBlur(): void {
        if (this.getControl().enabled) {
            this.setFieldValue();
            this._isFocused = false;
        }
    }

    setFieldValue(): void {
        const control = this.getControl();
        const value = this.config.value;
        if (control.value !== value) {
            control.setValue(value);
            control.markAsDirty();
        }
    }
}

