<dynamic-form-input-wrapper [formGroup]="getGroup()">
    <!-- input element -->
    <div class="control-label">
        <b>{{config.label}}</b>
    </div>
    <div class="timepicker">
        <mt-timepicker #timePicker *ngIf="showTimepicker" class="inline-block" [spinners]="false" [meridian]="true" (timePartBlur)="setOnBlur()" [(ngModel)]="timeModel" [ngModelOptions]="{standalone: true}"></mt-timepicker>

        <a class="btn btn-primary pull-right" (click)="clear()">Clear</a>
    </div>
</dynamic-form-input-wrapper>
